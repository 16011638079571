import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import RoutesService from '@/services/whatsapp/routes.service'

export default {
  name: 'WabaPhoneDetail',
  components: { HeaderTopDashboard },
  data () {
    return {
      selectedWaba: null,
      wabaPhones: [],
      debouncedInput: '',
      timeout: null,
      searchTerm: '',
      options: {},
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totals: 0,
      loading: false,
    }
  },
  computed: {
    wabaId () {
      return this.selectedWaba?.waba_id
    },
    headers () {
      return [
        {
          text: this.$t('Teléfono'),
          align: 'start',
          value: 'phone',
        },
        {
          text: this.$t('Id Teléfono'),
          value: 'phone_id',
          sortable: false,
        },
        {
          text: this.$t('Nombre'),
          value: 'display_name',
          sortable: false,
        },
        {
          text: this.$t('Validación'),
          value: 'route_status',
          sortable: false,
        },
        {
          text: this.$t('Estado'),
          value: 'route_waba_status',
          sortable: false,
        },
        {
          text: this.$t('Calidad'),
          value: 'route_qualities',
          sortable: false,
        },
        {
          text: this.$t('Límite mensajes'),
          value: 'route_level',
          sortable: false,
        },
        {
          text: this.$t('Configurar'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
  created () {
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    getParams (wabaId) {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        wabaId: wabaId,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getDataFromApi () {
      RoutesService.getAllFacebookManagerId().then((fbManagerIds) => {
        const facebookBusinessManagerId = this.$route.query.fb_manager_id ?? fbManagerIds[0].facebook_business_manager_id
        RoutesService.getWabasByFacebookManagerId({ facebookBusinessManagerId }).then((wabas) => {
          const wabaId = this.$route.query.waba_id ?? wabas[0].waba_id
          this.getWabaById(wabaId)
        })
      })
    },
    getWabaById (wabaId) {
      const params = {
        wabaId: wabaId,
      }
      RoutesService.getWabaById(params).then((response) => {
        this.selectedWaba = response
        this.getPhones()
      })
    },
    getPhones () {
      const params = this.getParams(this.selectedWaba.waba_id)
      console.log('params: ', params)
      RoutesService.getPhonesByWabaId(params).then((response) => {
        this.wabaPhones = response
        this.totals = this.wabaPhones.length
      })
    },
  },
}
